import React from 'react'
import { useForm } from "react-hook-form";
import {useMutation} from "@apollo/client";
import {INSERT_LANGUAGE} from "../../queries/languages";
import LocaleSelect from "../../components/form/locales";
import {insertLanguageUpdate} from "../../queries/updaters";

export default function LanguageForm(props) {
  const { close, projectId } = props;
  const { register, handleSubmit, errors } = useForm();
  const [insertLanguage, { loading, error }] = useMutation(INSERT_LANGUAGE, {update: insertLanguageUpdate});
  const onSubmit = async data => {
    console.log('data', data)
    try {
      const res = await insertLanguage({variables: {...data, projectId}});
      console.log('res', res);
      close && close();
    } catch (e) {
      console.log('e', e);
    }
  }

  if (loading) return <p>Loading</p>;
  if (error) return <p>An error occurred</p>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            Add a language
          </h3>
          <div className="text-left sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="localeCode" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Language Code
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <LocaleSelect ref={register({ required: true })} id="localeCode" name="locale_code" autoComplete="localeCode" />
            </div>
            { errors.language_code ? <p className="mt-2 text-sm text-red-600" id="localeCode-error">This field is required</p> : null }
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
          Create
        </button>
        <button onClick={() => close && close()} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
          Cancel
        </button>
      </div>
    </form>
  )
}
