import React from 'react'


export default function Item(props) {
  const {reference, translation, referenceLanguage, language, register, setValue} = props || {}
  let icon = (
    <div
      className="flex-shrink-0 shadow flex items-center justify-center w-16 bg-pink-600 text-white text-sm font-medium rounded-r-lg">
      <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
  )

  if(translation && translation.value) {
    icon = (
      <div
        className="flex-shrink-0 shadow flex items-center justify-center w-16 bg-green-600 text-white text-sm font-medium rounded-r-lg">
        <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    )

  }

  const copyValue = () => {
    setValue(`translation[${reference.key}]`, reference.value, {
      shouldValidate: true,
      shouldDirty: true
    });
  }
  //TODO: reset
  return (
    <div className="flex mb-4 shadow rounded-lg">
      <div className="flex-1">
        <div className="bg-white overflow-hidden rounded-l-lg ">
          <div className="px-4 py-5 sm:p-6">
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                  <div className="mt-6 sm:mt-5 space-y-3 sm:space-y-2">
                    <h3 className="text-xs">{ reference.key }</h3>
                    <div className="sm:grid sm:grid-cols-9 sm:gap-1 sm:items-start sm:border-t sm:border-gray-200 sm:pt-2">
                      <div className="sm:col-span-4">
                        <div className="block text-xs font-medium text-gray-400 my-2">{ referenceLanguage.locale_code } (Reference Value)</div>
                        <div className="block text-md font-medium text-gray-700">
                          { reference.value }
                        </div>
                      </div>
                      <div className="self-center text-center">
                        <button type="button" onClick={copyValue}>
                          <svg className="w-8 h-8 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </button>
                      </div>
                      <div className="sm:col-span-4">
                        <div className="mt-1 sm:mt-0">
                          <label htmlFor="about" className="block text-xs font-medium text-gray-400 my-2">
                            { language.locale_code } (New Value)
                          </label>
                          <textarea ref={register()} id={`translation[${reference.key}]`} name={`translation[${reference.key}]`} rows={2} className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" defaultValue={(translation && translation.value) || ""} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { icon }
    </div>
  )
}
