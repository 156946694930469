import React, {useEffect} from "react";
import {useTitle} from "../../hooks/use-title";

//TODO: maybe use lazy loading
export default function Home(props) {
  const { setTitleValue, title } = useTitle();
  useEffect(() => {
    setTitleValue('Gluuten');
  }, [title, setTitleValue]);


  return (
    <div className="text-center">
      <img className="inline-block h-20 w-20 mb-10" src="/gluuten.svg" alt="Workflow" />
      <p>
        Welcome
      </p>
    </div>
  )
}
