import React, { createContext, useReducer, useContext } from 'react';
import { createPortal } from 'react-dom';
import { Toast } from '../components/toast';

export const ToastContext = createContext();

const initialState = [];

export const ADD = 'ADD';
export const REMOVE = 'REMOVE';
export const REMOVE_ALL = 'REMOVE_ALL';

export const TYPE_INFO = 'info';
export const TYPE_SUCCESS = 'success';
export const TYPE_ERROR = 'error';

export const toastReducer = (state, action) => {
  switch (action.type) {
    case ADD:
      return [
        ...state,
        {
          id: +new Date(),
          content: action.payload.content,
          type: action.payload.type,
          timeout: action.payload.timeout,
        }
      ];
    case REMOVE:
      return state.filter(t => t.id !== action.payload.id);
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
};

export const ToastProvider = props => {
  const [toast, toastDispatch] = useReducer(toastReducer, initialState);
  const messageHandler = {
    info(message) {
      toastDispatch({
        type: ADD,
        payload: {
          content: { message },
          type: TYPE_INFO,
          timeout: 3500
        }
      })
    },
    success(message) {
      toastDispatch({
        type: ADD,
        payload: {
          content: { message },
          type: TYPE_SUCCESS,
          timeout: 3500
        }
      })
    },
    error(message) {
      toastDispatch({
        type: ADD,
        payload: {
          content: { message },
          type: TYPE_ERROR,
          timeout: 3500
        }
      })
    }
  }
  const toastData = { toast, toastDispatch, message: messageHandler };
  return (
    <ToastContext.Provider value={toastData}>
      {props.children}

      {createPortal(<Toast toast={toast} />, document.body)}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  return useContext(ToastContext);
};
