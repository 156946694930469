import { gql } from "@apollo/client";

export const TRANSLATION_FRAGMENT = gql`
  fragment Translation on Translation {
      __typename
      id
      key
      value
      created_at
      updated_at
  }
`;

export const GET_TRANSLATIONS = gql`
  query getTranslations($projectId:uuid!, $version:String!, $locale_code:String!, $namespace:String!, $translation_filter:Translation_bool_exp, $order_by:[Translation_order_by!]=[{key: asc}]) {
      Project(id: $projectId) {
          __typename
          id
          name
          reference_locale
          created_at
          updated_at
          
          Namespaces(where: {name: {_eq: $namespace}}) {
              __typename
              id
              name
          }

          Languages(where: {locale_code: {_eq: $locale_code}}) {
              __typename
              id
              locale_code
          }
          
          Versions(where: {name: {_eq: $version}}) {
              __typename
              id 
              name
          }
          
          ReferenceLanguage {
              __typename
              id
              locale_code
              Translations(where: $translation_filter, order_by: $order_by) {
                  ...Translation
              }
          }
          
          Translations(where: {
              Language: {locale_code: {_eq: $locale_code}}, 
              Version: {name: {_eq: $version}}, 
              Namespace: {name: {_eq: $namespace}}
          }) {
              ...Translation
          }
      }
  }
  ${TRANSLATION_FRAGMENT}
`;

export const INSERT_TRANSLATIONS = gql`
    mutation InsertTranslations($objects: [Translation_insert_input!]!, $update_columns: [Translation_update_column!]!) {
        insertTranslations(
            objects: $objects
            on_conflict: {
                constraint: Translation_key_language_id_namespace_id_version_id_project_id_,
                update_columns: $update_columns
            }
        ) {
            returning {
                __typename
                id
                key
                value
            }
        }
    }
`;

export const PUBLISH = gql`
    mutation publish($project_id: uuid!, $version_id: uuid!, $language_id: uuid!, $namespace_id: uuid!) {
        publishTranslations(config: {language_id: $language_id, namespace_id: $namespace_id, project_id: $project_id, version_id:$version_id}) {
            ok
        }
    }
`;
