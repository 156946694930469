import { gql } from "@apollo/client";

export const GET_PROJECTS = gql`
    query getProjects {
        Projects {
            __typename
            id
            name
            reference_locale
            created_at
            updated_at
            Versions {
                __typename
                id
                name
            }
        }
    }
`;

export const GET_PROJECT = gql`
    query getProject($id:uuid!) {
        Project(id: $id) {
            __typename
            id
            name
            reference_locale
            created_at
            updated_at
            Versions {
                __typename
                id
                name
            }
            Namespaces {
                __typename
                id
                name
            }
            Languages {
                __typename
                id
                locale_code
            }
            TranslationCounts {
                __typename
                version_id
                language_id
                namespace_id
                count
                Namespace {
                    __typename
                    id
                    name
                }
            }
        }
    }
`;

export const INSERT_PROJECT = gql`
    mutation insertProject($name: String!, $reference_locale: String!) {
        insertProjectRole(object: {
            user_role: admin
            Project: {
                data: {
                    name: $name,
                    reference_locale: $reference_locale,
                }
            }
        }) {
            __typename
            id
            user_id
            Project {
                __typename
                id
                name
                reference_locale
            }
        }
    }
  `;


export const INSERT_PROJECT_DEFAULTS = gql`
    mutation insertProjectDefaults($project_id: uuid!, $reference_locale: String!) {
        insertVersion(object: {
            name: "latest",
            project_id: $project_id
        }) {
            __typename
            id
            name
        }
        insertLanguage(object: {
            locale_code: $reference_locale,
            project_id: $project_id
        }) {
            __typename
            id
            locale_code
        }
    }
`;

export const GET_PROJECT_SETTINGS = gql`
    query projectSettings($id: uuid!, $user_id: String!) {
        Project(id: $id) {
            __typename
            ApiKeys {
                __typename
                id
                project_id
                created_at
            }
            UserInvites {
                __typename
                created_at
                email
                id
                project_id
                role
                updated_at
                ProjectRoles {
                    __typename
                    id
                    user_id
                }
            }
            ProjectRoles(where: {user_id: {_eq: $user_id}}) {
                __typename
                id
                updated_at
                user_id
                user_invite_id
                user_role
                project_id
                created_at
            }
            created_at
            description
            id
            name
            reference_locale
            updated_at
        }
    }
`;
