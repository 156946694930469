import {gql} from "@apollo/client";

export const GET_PROJECT_API_KEYS = gql`
    query getApiKeys($project_id: uuid!) {
        ApiKeys(where: {project_id: {_eq: $project_id}}) {
            __typename
            id
            project_id
            created_at
        }
    }
`

export const CREATE_PROJECT_API_KEY = gql`
    mutation createApiKey($project_id: uuid!) {
        insertApiKey(object: {project_id: $project_id}) {
            __typename
            id
            project_id
            created_at
        }
    }
`;

export const DELETE_API_KEY = gql`
    mutation deleteApiKey($id: uuid!) {
        deleteApiKey(id: $id) {
            __typename
            id
            project_id
        }
    }
`;
