import React from 'react'
import { useQuery } from '@apollo/client';
import {GET_LOCALES} from "../../../queries/locales";


const LocaleSelect = React.forwardRef((props, ref) => {
  const { data, loading, error } = useQuery(GET_LOCALES);

  if (loading) return  <p>Loading</p>;
  if (error) return <p>ERROR</p>;
  if (!data) return <p>Not found</p>;

  let optionsItems = (data.Locales || []).map((locale) => {
    return (<option key={locale.value} value={locale.value}>{locale.value} {locale.label}</option>)
  })

  return (
    <select ref={ref} {...props} className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
      { optionsItems }
    </select>
  )
})

export default LocaleSelect
