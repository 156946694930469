import { gql } from "@apollo/client";

export const INSERT_NAMESPACE = gql`
    mutation insertNamespace($name:String!, $projectId:uuid!) {
        insertNamespace(object: {name: $name, project_id: $projectId}) {
            __typename
            id
            name
            project_id
        }
    }
`;
