import { gql } from "@apollo/client";

export const INSERT_LANGUAGE = gql`
    mutation insertLanguage($locale_code:String!, $projectId:uuid!) {
        insertLanguage(object: {locale_code: $locale_code, project_id: $projectId}) {
            __typename
            id
            locale_code
            project_id
        }
    }
`;
