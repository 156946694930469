import React, { useState } from 'react'
import axios from "axios";
import {useMutation} from "@apollo/client";
import {PUBLISH} from "../../queries/translations";
import {useAuth} from "../../hooks/use-auth";
import {useToastContext} from "../../hooks/use-toast";


export default function Import(props) {
  const [ importing, setImporting ] = useState(false);
  const { message } = useToastContext();
  const { queryResult: {data, loading, error, refetch }} = props
  const hiddenFileInput = React.useRef(null);
  let auth = useAuth();
  const { authState={} } = auth || {};
  const { token } = authState || {};

  const { Project: {id, Namespaces, Languages, Versions} = {}} = data || {};
  const [publish] = useMutation(PUBLISH, {
    onCompleted({ publishTranslations }) {
      console.log('pr', publishTranslations);
    }
  });

  const disabled = loading || error || !data;
  const namespace = Namespaces && Namespaces[0];
  const language = Languages && Languages[0];
  const version = Versions && Versions[0];

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = async event => {
    const importFile = event.target.files[0];

    try {
      setImporting(true);
      let formData = new FormData();
      formData.append("filename", importFile);
      let endpoint = process.env.REACT_APP_BACKEND_URL || 'http://localhost:2000';
      await axios.post(`${endpoint}/import/json/${id}/${version.id}/${language.id}/${namespace.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token ? `Bearer ${token}` : "",
        }
      });
      await publish({variables: {
          project_id: id,
          namespace_id: namespace.id,
          language_id: language.id,
          version_id: version.id,
        }})
      setImporting(false);
      message.success('Translations imported!')
      event.target.value = null;
      refetch();
    } catch (e) {
      setImporting(false);
      console.error(e);
      message.error('Error importing translations!')
      event.target.value = null;

    }
  };

  return (
    <>
      <button
        disabled={ disabled }
        onClick={handleClick}
        className="mr-2 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
        { importing ? <svg className="animate-spin text-gray-900 -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                              fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                    strokeWidth="4" />
            <path className="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
          </svg>
          : null }
        Import
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
    </>
  )
}
