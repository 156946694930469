import {gql} from "@apollo/client";

export const insertLanguageUpdate = (cache, { data: { insertLanguage } }) => {
  try {
    const { project_id } = insertLanguage
    cache.modify({
      id: cache.identify({id: project_id, __typename: 'Project'}),
      fields: {
        Languages(existingLanguageRefs = [], { readField }) {
          const newLanguageRef = cache.writeFragment({
            data: insertLanguage,
            fragment: gql`
                fragment NewLanguage on Language {
                    __typename
                    id
                    locale_code
                }
            `
          });
          return [...existingLanguageRefs, newLanguageRef];
        }
      }
    });
  } catch(e) {
    console.error(e);
  }
}

export const insertNamespaceUpdate = (cache, { data: { insertNamespace } }) => {
  try {
    const { project_id } = insertNamespace
    cache.modify({
      id: cache.identify({id: project_id, __typename: 'Project'}),
      fields: {
        Namespaces(existingNamespaceRefs = [], { readField }) {
          const newNamespaceRef = cache.writeFragment({
            data: insertNamespace,
            fragment: gql`
                fragment NewNamespace on Namespace {
                    __typename
                    id
                    name
                }
            `
          });
          return [...existingNamespaceRefs, newNamespaceRef];
        }
      }
    });
  } catch(e) {
    console.error(e);
  }
}

export const insertProjectUpdate = (cache, { data: { insertProjectRole } }) => {
  try {
    const { Project } = insertProjectRole
    console.log('d', Project);
    cache.modify({
      fields: {
        Projects(existingProjectRefs = []) {
          console.log('ep', existingProjectRefs)
          const newProjectRef = cache.writeFragment({
            data: Project,
            fragment: gql`
                fragment NewProject on Project {
                    __typename
                    id
                    name
                    reference_locale
                }
            `
          });
          console.log('new', newProjectRef);
          return [...existingProjectRefs, newProjectRef];
        }
      }
    });
  } catch(e) {
    console.error(e);
  }
}

export const insertApiKeyUpdate = (cache, { data: { insertApiKey } }) => {
  try {
    const { project_id } = insertApiKey
    cache.modify({
      id: cache.identify({id: project_id, __typename: 'Project'}),
      fields: {
        ApiKeys(existingApiKeyRefs = [], { readField }) {
          const newApiKeyRef = cache.writeFragment({
            data: insertApiKey,
            fragment: gql`
                fragment NewApiKey on ApiKey {
                    __typename
                    id
                    project_id
                    created_at
                }
            `
          });

          return [...existingApiKeyRefs, newApiKeyRef];
        }
      }
    });
  } catch(e) {
    console.error(e);
  }
}

export const deleteApiKeyUpdate = (cache, { data }) => {
  const { deleteApiKey } = data || {};
  const { id, project_id } = deleteApiKey || {};
  cache.modify({
    id: cache.identify({id: project_id, __typename: 'Project'}),
    fields: {
      ApiKeys(existingApiKeyRefs = [], {readField}) {
        return existingApiKeyRefs.filter((t) => (readField('id', t) !== id));
      }
    }}
  )
}


export const insertUserInviteUpdate = (cache, { data: { insertUserInvite } }) => {
  try {
    const { project_id } = insertUserInvite
    cache.modify({
      id: cache.identify({id: project_id, __typename: 'Project'}),
      fields: {
        UserInvites(existingUserInviteRefs = [], { readField }) {
          const newUserInviteRef = cache.writeFragment({
            data: insertUserInvite,
            fragment: gql`
                fragment NewUserInvite on UserInvite {
                    __typename
                    id
                    email
                    role
                    project_id
                }
            `
          });

          return [...existingUserInviteRefs, newUserInviteRef];
        }
      }
    });
  } catch(e) {
    console.error(e);
  }
}
