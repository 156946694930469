import React, { useState, useRef } from "react";
import { Transition } from '@headlessui/react'
import useOutsideClick from "../../hooks/use-outside-click";
import {useAuth} from "../../hooks/use-auth";
import {Link} from "react-router-dom";


function ProfileButton(props) {
  const [isOpen, setIsOpen] = useState(false)
  const [imageLoadError, setImageLoadError] = useState(false)
  let auth = useAuth();
  const { authState } = auth;
  const { user } = authState || {};
  let photo;

  const initials = user && (user.displayName || user.email).split(" ").map((n)=>n[0]).join("").toUpperCase();
  if(user && user.photoURL) {
    photo = user.photoURL;
  }

  const showPhoto = photo && !imageLoadError;

  const ref = useRef();

  useOutsideClick(ref, () => {
    if(isOpen) {
      setIsOpen(false);
    }
  });

  if(authState.status === 'in') {
    return (
      <div className="ml-3 relative" ref={ref}>
        <div>
          <button onClick={() => {
            setIsOpen(!isOpen)
          }} className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-haspopup="true">
            <span className="sr-only">Open user menu</span>
            { showPhoto ? <img onError={() => {
              setImageLoadError(true);
            }} className="h-8 w-8 rounded-full" src={ photo } alt="" /> : <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
  <span className="text-sm font-medium leading-none text-white">{ initials || '' }</span>
</span> }
          </button>
        </div>
        {/*
          Profile dropdown panel, show/hide based on dropdown state.

          Entering: "transition ease-out duration-100"
            From: "transform opacity-0 scale-95"
            To: "transform opacity-100 scale-100"
          Leaving: "transition ease-in duration-75"
            From: "transform opacity-100 scale-100"
            To: "transform opacity-0 scale-95"
        */}
        <Transition
          show={isOpen}
          enter="transition-opacity ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition-opacity ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <a href="#" onClick={async e => {
              e.preventDefault();
              await auth.signout()
              setIsOpen(false);
            }} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sign out</a>
          </div>
        </Transition>
      </div>
    )
  } else {
    return (
      <Link to="/sign-in" className="text-white px-3 py-2 rounded-md text-sm font-medium">Sign in</Link>
    )
  }
}

export default ProfileButton
