import {createHttpLink} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {setContext} from "@apollo/client/link/context";
import { fromPromise } from "@apollo/client";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const createErrorLink = (auth) => {
  let isRefreshing = false;
  let pendingRequests = [];

  const resolvePendingRequests = () => {
    pendingRequests.map(callback => callback());
    pendingRequests = [];
  };

  return onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          const { message, locations, path, extensions } = err;
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
          if (extensions.code === 'invalid-jwt') {
            let forward$;

            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                auth.refreshToken()
                  .then((accessToken) => {
                    // Store the new tokens for your auth link
                    resolvePendingRequests();
                    return accessToken;
                  })
                  /*eslint-disable */
                  .catch(error => {
                    pendingRequests = [];

                    // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                    return;
                  })
                  .finally(() => {
                    /*eslint-disable */
                    isRefreshing = false;
                  })
              ).filter(value => Boolean(value));
            } else {
              // Will only emit once the Promise is resolved
              forward$ = fromPromise(
                new Promise(resolve => {
                  /*eslint-disable */
                  pendingRequests.push(() => resolve());
                })
              );

              /*eslint-enable */
            }

            return forward$.flatMap(() => forward(operation));
          }
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // if you would also like to retry automatically on
        // network errors, we recommend that you use
        // apollo-link-retry
      }
    }
  );
}

const createAuthLink = ({ authState={} }={}) => setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = authState?.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      // Authorization: token ? `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjQ4OTQ5ZDdkNDA3ZmVjOWIyYWM4ZDYzNWVjYmEwYjdhOTE0ZWQ4ZmIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiTWVpa28gVWRyYXMiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2hlSkR3ME9OLXliejF6SE9GVXR3ZENsS1ptYmhCNDdLalhfQWw3PXM5Ni1jIiwiaHR0cHM6Ly9oYXN1cmEuaW8vand0L2NsYWltcyI6eyJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJ1c2VyIiwieC1oYXN1cmEtYWxsb3dlZC1yb2xlcyI6WyJ1c2VyIl0sIngtaGFzdXJhLXVzZXItaWQiOiJmeFdYOWNBb2tBZ2FJVjZuVUQ3VWkwWmZQOXcyIn0sImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9nbHV1dGVuLWRldiIsImF1ZCI6ImdsdXV0ZW4tZGV2IiwiYXV0aF90aW1lIjoxNjE0OTI4MzI5LCJ1c2VyX2lkIjoiZnhXWDljQW9rQWdhSVY2blVEN1VpMFpmUDl3MiIsInN1YiI6ImZ4V1g5Y0Fva0FnYUlWNm5VRDdVaTBaZlA5dzIiLCJpYXQiOjE2MTUyOTEzMjQsImV4cCI6MTYxNTI5NDkyNCwiZW1haWwiOiJtZWlrb3VkcmFzQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7Imdvb2dsZS5jb20iOlsiMTA5MDE4NjgwMTI1ODA2NjQ2MDcyIl0sImVtYWlsIjpbIm1laWtvdWRyYXNAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiZ29vZ2xlLmNvbSJ9fQ.YxpQqNkLanX3EPi6jHM-M4_J7hybyvnaPVFYODgLKGBHt-xmQeVS8CbTXeV0bg7k2ykv2wAQC11HAvt8QPfta09a0E5z40ykT87kxkSk0F6kvhvHa649szhwTVqhQIPd9UfT__cn6kZtvUu9FOapyqfcNXd9vB1ktmIdFJNmgGQpKY-o0G9my7f0SCAInv95cXKFutSV4s99oM1MgjSEMRGPyVUZp6iZ3H1yWX5Vr7qJpRlkL8xOezBvnWDQZI2GEmLEqK342QJayfNFN9LA53zQQe43Y2QW5-2PTs_Hx9mscD00_AQZEOZqPjbYszuyuVwrlyPgRuwS0qeu9Txl0Q` : "",
    }
  }
});

export {
  httpLink,
  createErrorLink,
  createAuthLink
}
