import React from 'react'

export default function DefaultTabs(props) {
  const { options, current, onChange } = props || {};

  let tabs = [] , mobileTabs = [];

  (options || []).forEach((option, index) => {
    const active = option.value === current;
    tabs.push(
      <a
        key={option.value}
        className={ `${ index !== 0 ? 'ml-8': ''} ${active ? 'border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300'} whitespace-no-wrap py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none` }
        href="#" onClick={(e) => {
          e.preventDefault();
          onChange(option.value, e);
      }}>{ option.label }</a>
    );
    mobileTabs.push(
      <option key={option.value} value={option.value}>{ option.label }</option>
    )
  })
  return (
    <div className="pb-5">
      <div className="sm:hidden">
        <select
          className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
          aria-label="Selected tab"
          value={current}
          onChange={(e) => {
            onChange(e.currentTarget.value, e);
          }}
        >
          { mobileTabs }
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            { tabs }
          </nav>
        </div>
      </div>
    </div>
  )
}

/*
<a
            className="whitespace-no-wrap py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            href="#">My Account</a><a
            className="whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            href="#">Company</a><a
            className="whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-indigo-500 font-medium text-sm leading-5 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700"
            href="#" aria-current="page">Team Members</a><a
            className="whitespace-no-wrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            href="#">Billing</a>
 */
