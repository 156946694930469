import React, {useEffect, useRef} from 'react'
import {useMutation, useQuery} from "@apollo/client";
import {useRouteMatch} from "react-router-dom";
import {CREATE_PROJECT_API_KEY, DELETE_API_KEY} from "../../queries/apiKeys"
import { GET_PROJECT_SETTINGS } from "../../queries/projects";
import InviteForm from "./InviteForm";
import {SmallModal} from "../../components/modals";
import {deleteApiKeyUpdate, insertApiKeyUpdate} from "../../queries/updaters";
import {useTitle} from "../../hooks/use-title";
import {useAuth} from "../../hooks/use-auth";
import {useToastContext} from "../../hooks/use-toast";

function copyToClipboard(text) {
  let dummy = document.createElement("textarea");
  // to avoid breaking orgain page when copying more words
  // cant copy when adding below this code
  // dummy.style.display = 'none'
  document.body.appendChild(dummy);
  //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export default function Settings () {
  let { params } = useRouteMatch();
  const { message } = useToastContext();

  let auth = useAuth();
  const { authState={} } = auth || {};
  const { projectId } = params || {};
  const { data } = useQuery(GET_PROJECT_SETTINGS, {variables: {id: projectId, user_id: authState?.user?.uid}});
  const [createProjectApiKey] = useMutation(CREATE_PROJECT_API_KEY, {update: insertApiKeyUpdate});
  const [deleteApiKey] = useMutation(DELETE_API_KEY, {update: deleteApiKeyUpdate});
  const { setTitleValue, title } = useTitle();
  useEffect(() => {
    setTitleValue(`Settings`, true);
  }, [title, setTitleValue]);

  const inviteModal = useRef(null);

  const { Project } = data || {};
  const { UserInvites, ApiKeys, ProjectRoles } = Project || {}
  const isAdmin = ProjectRoles?.[0]?.user_role === 'admin';

  const closeInvite = () => {
    inviteModal.current.close();
  }

  return <div>
      <div className="space-y-8 sm:space-y-5">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Invited users
            </h3>
          </div>
        </div>
        { isAdmin ? <div className="mb-6">
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <button type="button"
                    onClick={() => inviteModal.current.open()}
                    className="relative inline-flex items-center px-4 py-2 rounded-md border border-indigo-300 text-white text-sm font-medium bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <svg className="mr-3 -ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
              Invite user
            </button>
          </span>
        </div> : null }
        { UserInvites && UserInvites.length > 0 ? <div>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                      <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Email
                      </th>
                      <th scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Role
                      </th>
                      <th scope="col"
                          className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      UserInvites.map((invite, index) => {
                        return (
                          <tr className={ index % 2 === 1 ? "bg-white" : "bg-gray-50" } key={invite.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              { invite.email }
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              { invite.role }
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                              {invite.ProjectRoles.length > 0 ? <span className="text-green-500">Active</span> : "Invited"}
                            </td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null }
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              API keys
            </h3>
          </div>
        </div>
        { isAdmin ? <div className="mb-6">
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <button type="button"
                    onClick={async () => {
                      try {
                        await createProjectApiKey({variables: {project_id: projectId}});
                        message.success('API key created');
                      } catch (e) {
                        message.error('Failed to create API key');
                      }
                    }}
                    className="relative inline-flex items-center px-4 py-2 rounded-md border border-indigo-300 text-white text-sm font-medium bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <svg className="mr-3 -ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
              Create API key
            </button>
          </span>
        </div> : null }
        { ApiKeys && ApiKeys.length > 0 ? <div>
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                      <tr>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          API Key
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        ApiKeys.map((apiKey, index) => {
                          return (
                            <tr className={ index % 2 === 1 ? "bg-white" : "bg-gray-50" } key={apiKey.id}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                { apiKey.id }
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a href="#" onClick={e => {
                                  e.preventDefault();
                                  copyToClipboard(apiKey.id);
                                }} className="text-indigo-600 hover:text-indigo-900">Copy</a>
                                { isAdmin ? <a href="#" onClick={async e => {
                                  e.preventDefault();
                                  try {
                                    const { data } = await deleteApiKey({variables: {id: apiKey.id}});
                                    const { deleteApiKey:deleted } = data || {};
                                    if(deleted) {
                                      message.success('API key deleted')
                                    } else {
                                      message.error('Error deleting API key')
                                    }
                                  } catch(e) {
                                    message.error('Error deleting API key')
                                  }
                                }} className="text-red-600 ml-5 hover:text-red-900">Delete</a> : null }
                              </td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null }
      </div>
      <SmallModal ref={inviteModal}>
        <InviteForm projectId={ projectId } close={ closeInvite } />
      </SmallModal>
  </div>
}
