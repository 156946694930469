import React, {createContext, useContext, useState} from "react";


const titleContext = createContext();

export function ProvideTitle({ children }) {
  const title = useProvideTitle();
  return <titleContext.Provider value={title}>{children}</titleContext.Provider>;
}

export const useTitle = () => {
  return useContext(titleContext);
};

function useProvideTitle() {
  const [title, setTitle] = useState("" );
  const [backVisible, setBackVisible] = useState(false );

  const setTitleValue = (title, back=false) => {
    setTitle(title)
    setBackVisible(back)
  }

  return {
    title,
    backVisible,
    setTitle,
    setBackVisible,
    setTitleValue,
  }
}
