import { gql } from "@apollo/client";

export const INSERT_USER_INVITE = gql`
    mutation insertUserInvite($email: String!, $projectId:uuid!, $role:UserRole_enum!) {
        insertUserInvite(object: {email: $email, project_id: $projectId, role: $role}) {
            __typename
            id
            email
            role
            project_id
        }
    }
`;


export const CREATE_PROJECT_ROLE = gql`
  mutation createProjectRole($key: uuid!) {
      createProjectRole(key: $key) {
          __typename
          role_id
      }
  }
`

export const GET_PROJECT_INVITES = gql`
    query getProjectInvites($project_id:uuid!) {
        UserInvites(where: {project_id: {_eq: $project_id}}) {
            __typename
            id
            email
            project_id
            role
            created_at
            ProjectRoles {
                __typename
                id
                user_id
            }
        }
    }
`
