import React from 'react'
import { useForm } from "react-hook-form";
import {useMutation} from "@apollo/client";
import {INSERT_TRANSLATIONS, PUBLISH} from "../../queries/translations";
import {useToastContext} from "../../hooks/use-toast";

export default function TranslationForm(props) {
  const { close, queryResult: {data, loading, error }, onSuccess } = props;
  const { message } = useToastContext();
  const { Project: {id: projectId, Namespaces, Languages, Versions} = {}} = data || {};
  const { register, handleSubmit, errors, formState, reset } = useForm();
  const [insertTranslations, insertStatus] = useMutation(INSERT_TRANSLATIONS, { update(cache, { data: { insertTranslations } }) {
    // console.log('cache', cache, insertTranslations)
    }});
  const [publish] = useMutation(PUBLISH, {
    onCompleted({ publishTranslations }) {
      console.log('pr', publishTranslations);
    }
  });
  const { isSubmitting } = formState;
  if (loading) return null;
  if (error) return null;
  if (!data) return null;
  const namespace = Namespaces[0];
  const language = Languages[0];
  const version = Versions[0];
  const versionId = version.id;
  const languageId = language.id;
  const namespaceId = namespace.id;
  const onSubmit = async data => {
    try {
      await insertTranslations({variables: {objects:[{
        ...data,
        project_id: projectId,
        version_id: versionId,
        language_id: languageId,
        namespace_id: namespaceId,
      }], update_columns: []}});
      await publish({variables: {
          project_id: projectId,
          namespace_id: versionId,
          language_id: languageId,
          version_id: namespaceId,
        }})
      message.success('Translation added!')
      reset();
      onSuccess && onSuccess();
    } catch (e) {
      message.error('Error adding translation!')
      console.log('e', e);
    }
  }

  if (insertStatus.loading) return <p>Loading</p>;
  if (insertStatus.error) return <p>An error occurred</p>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            Add a translation
          </h3>
          <div className="text-left sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="key" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Key
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input ref={register({ required: true })} type="text" name="key" id="key" autoComplete="key" className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
            </div>
            { errors.key ? <p className="mt-2 text-sm text-red-600" id="key-error">This field is required</p> : null }
          </div>
          <div className="text-left sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="value" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Value
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input ref={register({ required: true })} type="text" name="value" id="value" autoComplete="value" className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
            </div>
            { errors.value ? <p className="mt-2 text-sm text-red-600" id="value-error">This field is required</p> : null }
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
          { isSubmitting ? <svg className="animate-spin text-gray-900 -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                      strokeWidth="4" />
              <path className="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            : null }
          Create
        </button>
        <button onClick={() => close && close()} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
          Cancel
        </button>
      </div>
    </form>
  )
}
