import React from 'react'
import {useForm} from "react-hook-form";
import {useMutation} from "@apollo/client";
import {INSERT_TRANSLATIONS, PUBLISH} from "../../queries/translations";
import Item from "./Item";
import {Transition} from "@headlessui/react";
import {useToastContext} from "../../hooks/use-toast";

export default function Translations(props) {
  const { register, handleSubmit, formState, reset, setValue } = useForm();
  const { queryResult: {data, loading, error, refetch }} = props
  const { message } = useToastContext();
  const { Project: {id, Namespaces, Languages, ReferenceLanguage, Versions, Translations} = {}} = data || {};
  // TODO: maybe add update values to cache?
  const [insertTranslations] = useMutation(INSERT_TRANSLATIONS, {
    onCompleted({ insertTranslations }) {
      console.log('pr', insertTranslations);
    }
  });
  const [publish] = useMutation(PUBLISH, {
    onCompleted({ publishTranslations }) {
      console.log('pr', publishTranslations);
    }
  });
  const { isDirty, dirtyFields, isSubmitting } = formState;
  const count = Object.keys(dirtyFields?.translation || {}).length;

  let items;
  if (loading) return  <p>Loading</p>;
  if (error) return <p>ERROR</p>;
  if (!data) return <p>Not found</p>;


  const namespace = Namespaces[0];
  const language = Languages[0];
  const version = Versions[0];

  const onSubmit = async (data) => {
    let insertData = [];
    for(let key in (data?.translation || {})) {
      if((dirtyFields?.translation || {})[key]) {
        insertData.push({
          project_id: id,
          namespace_id: namespace.id,
          language_id: language.id,
          version_id: version.id,
          key,
          value: data.translation[key]
        })
      }
    }
    try {
      if(insertData.length > 0) {
        const res = await insertTranslations({variables: {objects: insertData, update_columns: ['value', 'updated_at']}});
        await publish({variables: {
            project_id: id,
            namespace_id: namespace.id,
            language_id: language.id,
            version_id: version.id,
          }})
        console.log('res', res);
        message.success('Translations published');
        refetch();
        reset();
      }
    } catch(e) {
      message.error('Error publishing translations');
      console.log('e',e)
    }
    console.log('submit', data, insertData);
  };
  items = (ReferenceLanguage?.Translations || []).map((reference) => {
    const translation = (Translations || []).find((t) => t.key===reference.key);
    return (
      <Item
        key={reference.key}
        language={ language }
        referenceLanguage={ReferenceLanguage}
        reference={reference}
        translation={ translation }
        register={register}
        setValue={setValue}
        isDirty={dirtyFields?.translation?.[reference.key] || false}
      />
    )
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      { items.length > 0 ? items : <div>No translations yet</div> }
      {/*<div className="pt-5">*/}
      {/*  <div className="flex justify-end">*/}
      {/*    { count ? <div>Updated: {count}</div> : null }*/}

      {/*    <button disabled={!isDirty} type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">*/}
      {/*      Cancel*/}
      {/*    </button>*/}
      {/*    <button type="submit" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">*/}
      {/*      Save*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}
        <div className="fixed inset-x-0 bottom-0">
          <Transition
            show={isDirty}
            enter="transform transition ease-in-out duration-300 sm:duration-300"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="transform transition ease-in-out duration-300 sm:duration-300"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <div className="bg-green-600">
              <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between flex-wrap">
                  <div className="w-0 flex-1 flex items-center">
                    <p className="ml-3 font-medium text-white truncate">
                    <span className="">
                      Updated translations: {count}
                    </span>
                    </p>
                  </div>
                  <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                    <button
                      disabled={isSubmitting}
                      type="submit"
                       className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-800 bg-white hover:bg-green-50">
                      { isSubmitting ? <svg className="animate-spin text-gray-900 -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                           fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      : null }
                      Save
                    </button>
                  </div>
                  <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                    <button type="button"
                            onClick={() => {
                              reset();
                            }}
                            className="-mr-1 flex p-2 rounded-md hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2">
                      <span className="sr-only">Dismiss</span>

                      <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
    </form>
  )
}
