import { gql } from "@apollo/client";

export const GET_LOCALES = gql`
    query getLocales {
        Locales {
            __typename
            value
            label
        }
    }
`
