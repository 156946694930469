import {gql} from "@apollo/client";

export const GET_USER_PERMISSIONS = gql`
    query getUserPermissions($user_id:String!) {
        UserPermissions(where: {user_id: {_eq: $user_id}}) {
            __typename
            id
            user_id
            create_project
        }
    }
`
