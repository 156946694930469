import './App.css';
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {ApolloClient, ApolloProvider} from "@apollo/client";

import { cache } from "./cache";

import { Create, Edit, Settings } from "./pages/project";
import { DefaultLayout } from "./components/layouts"
import { DashboardList } from "./pages/dashboard";
import LoginPage from "./pages/login";
import SignUpPage from "./pages/sign-up";
import { useAuth} from "./hooks/use-auth";
import {ProvideTitle} from "./hooks/use-title";
import Home from './pages/home'

import { httpLink, createAuthLink, createErrorLink } from "./apollo";


function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  const { authState={} } = auth || {};
  // console.log('authState', authState);
  if(authState.status === 'loading') {
    return <>Loading</>
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState?.status === 'in' ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function App() {
  let auth = useAuth();

  const authLink = createAuthLink(auth);
  const errorLink = createErrorLink(auth);

  const client = new ApolloClient({
    cache,
    link: errorLink.concat(authLink.concat(httpLink)),
  });

  return (
    <ProvideTitle>
      <ApolloProvider client={client}>
        <Router>
          <DefaultLayout>
            <Switch>
              <Route path="/sign-in">
                <LoginPage />
              </Route>
              <Route path="/sign-up/:key?">
                <SignUpPage />
              </Route>
              <Route path={"/"} exact>
                <Home />
              </Route>
              <PrivateRoute path="/dashboard">
                <DashboardList />
              </PrivateRoute>
              <PrivateRoute path="/project/create">
                <Create />
              </PrivateRoute>
              <PrivateRoute path="/project/edit/:projectId/:version">
                <Edit />
              </PrivateRoute>
              <PrivateRoute path="/project/settings/:projectId">
                <Settings />
              </PrivateRoute>
            </Switch>
          </DefaultLayout>
        </Router>
      </ApolloProvider>
    </ProvideTitle>
  );
}

export default App;
