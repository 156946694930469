import React, {useEffect} from 'react'
import { useForm } from "react-hook-form";
import LocaleSelect from "../../components/form/locales";
import {useMutation, useQuery} from "@apollo/client";
import {INSERT_PROJECT, INSERT_PROJECT_DEFAULTS} from "../../queries/projects";
import { GET_USER_PERMISSIONS } from "../../queries/userPermissions";
import {useHistory} from "react-router-dom";
import {insertProjectUpdate} from "../../queries/updaters";
import { useToastContext } from '../../hooks/use-toast'
import {useAuth} from "../../hooks/use-auth";
import {useTitle} from "../../hooks/use-title";

export default function Create () {
  let history = useHistory();
  let auth = useAuth();
  const { authState={} } = auth || {};
  const { setTitleValue, title } = useTitle();
  useEffect(() => {
    setTitleValue('Create a project', true);
  }, [title, setTitleValue]);

  const { message } = useToastContext();
  const { data } = useQuery(GET_USER_PERMISSIONS, {fetchPolicy: "no-cache", variables: {user_id: authState?.user?.uid}})
  const { UserPermissions } = data || {};
  const permission = UserPermissions && UserPermissions[0];

  const createProjectPermission = permission && permission.create_project;
  const { register, handleSubmit, errors } = useForm();
  const [insertProject, { loading, error }] = useMutation(INSERT_PROJECT, {
    onCompleted({ project }) {
      console.log('pr', project);
    },
    update: insertProjectUpdate
  });
  const [insertProjectDefaults, defaultState] = useMutation(INSERT_PROJECT_DEFAULTS, {
    onCompleted({ project }) {
      console.log('pr', project);
    }
  });
  const onSubmit = async data => {
    console.log('data', data)
    try {
      const res = await insertProject({variables: {...data}});
      const { data: { insertProjectRole: { Project }} } = res || {};
      console.log('res', res, data);
      await insertProjectDefaults({variables: {...data, project_id: Project.id}});
      message.success('Project created');
      history.push('/dashboard')
    } catch (e) {
      message.error('Creating project failed');
      console.log('e', e);
    }
  }


  if (loading || defaultState.loading) return <p>Loading</p>;
  if (error || defaultState.error) return <p>An error occurred</p>;

  if(!createProjectPermission) {
    return <p>You do not have permission to create projects</p>
  }

  return <div>
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Create Project
            </h3>
            {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">*/}
            {/*  This information will be displayed publicly so be careful what you share.*/}
            {/*</p>*/}
          </div>
          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Project name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <input ref={register({ required: true })} type="text" name="name" id="name" autoComplete="name" className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>
              { errors.name ? <p className="mt-2 text-sm text-red-600" id="name-error">This field is required</p> : null }
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="referenceLocale" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Reference locale
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <LocaleSelect ref={register({ required: true })} id="referenceLocale" name="reference_locale" autoComplete="referenceLocale" defaultValue={'en'} />
              </div>
              { errors.reference_locale ? <p className="mt-2 text-sm text-red-600" id="referenceLocale-error">This field is required</p> : null }
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea ref={register} id="description" name="description" rows={3} className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" defaultValue={""} />
                <p className="mt-2 text-sm text-gray-500">Write a few sentences about the project.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Cancel
          </button>
          <button type="submit" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Save
          </button>
        </div>
      </div>
    </form>

  </div>
}
