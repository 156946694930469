import React from 'react'
import Cog from "../icons/Cog";
import PencilAlt from "../icons/PencilAlt";
import {Link} from "react-router-dom";

export default function Project(props) {
  const { project } = props
  if(!project) {
    return null
  }
  const defaultVersion = project.Versions[0]

  if(!defaultVersion) {
    return null;
  }
  return (
    <div className="bg-white rounded-lg shadow divide-y divide-gray-200">
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="text-gray-900 text-lg font-medium truncate">{ project.name }</h3>
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{ project.reference_locale }</span>
            </div>
          </div>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="w-0 flex-1 flex">
              <Link to={`/project/edit/${project.id}/${defaultVersion.name}`} className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                <PencilAlt className="w-5 h-5 text-gray-400" />
                <span className="ml-3">Edit</span>
              </Link>
            </div>
            <div className="-ml-px w-0 flex-1 flex">
              <Link to={`/project/settings/${project.id}`} className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                <Cog className="w-5 h-5 text-gray-400" />
                <span className="ml-3">Settings</span>
              </Link>
            </div>
          </div>
        </div>
    </div>
  )
}
