import React, { useRef, useEffect } from 'react'
import {Link, Route, Switch, useRouteMatch} from "react-router-dom";
import List from "../translation/List";
import {useQuery} from "@apollo/client";
import {GET_PROJECT} from "../../queries/projects";
import { SmallModal } from "../../components/modals";
import NamespaceForm from "./NamespaceForm";
import LanguageForm from "./LanguageForm";
import {useTitle} from "../../hooks/use-title";

const getTranslatedPercentage = (namespace, language, counts, referenceLanguage) => {
  const { id, name } = namespace;
  // console.log('counts', id, name);
  const namespaceCount = counts.find((count) => {
    return count.namespace_id === id && language.id === count.language_id;
  }) || {count: 0}
  const referenceNamespaceCount = counts.find((count) => {
    return count.Namespace.name === name && referenceLanguage.id === count.language_id;
  }) || {count: 0}

  if(namespaceCount.count === 0) {
    return 0
  }

  const ratio = namespaceCount.count / referenceNamespaceCount.count;
  if(ratio > 1) {
    return 1;
  }
  // console.log('rf', referenceNamespaceCount, namespaceCount);
  return Math.round(ratio * 10000)/10000;
}

const ratioToPercentage = (ratio) => {
  return `${Math.round(ratio * 10000)/100}%`
}

//TODO: version filtering
export default function Edit() {
  const namespaceModal = useRef(null);
  const languageModal = useRef(null);
  let { path, url, params } = useRouteMatch();
  const { projectId } = params || {}
  const { data, loading, error } = useQuery(GET_PROJECT, {variables: {id: projectId}})
  const { setTitleValue, title } = useTitle();
  useEffect(() => {
    const { Project } = data || {};
    if(Project) {
      setTitleValue(`Project: ${Project.name}`, true);
    }
  }, [title, data, setTitleValue]);
  let languageItems;

  // console.log('data', data, error);
  if (loading) return  <p>Loading</p>;
  if (error) return <p>ERROR</p>;
  if (!data) return <p>Not found</p>;

  const { Project } = data;


  const ReferenceLanguage = (Project.Languages || []).find((language) => {
    return Project.reference_locale === language.locale_code;
  });

  // console.log('p', Project);
  languageItems = (Project.Languages || []).map(language => {
    const languageId = language.id;
    const languageKeyCount = (Project.TranslationCounts || []).reduce((sum, count) => {
      if(languageId === count.language_id) {
        sum += count.count;
      }
      return sum;
    }, 0)
    const namespaceData = (Project.Namespaces || [])
    const namespaces = namespaceData.map((namespace, index) => {
      const translatedRatio = getTranslatedPercentage(namespace, language,Project.TranslationCounts || [], ReferenceLanguage)
      const count = (Project.TranslationCounts || []).find(count => {
        return count.namespace_id === namespace.id && count.language_id === language.id;
      })
      return (
        <div key={namespace.id} className="">
          <div
            className="inset-0 flex items-end justify-center pointer-events-none sm:items-start sm:justify-end">
            <div className={["max-w w-full bg-white shadow pointer-events-auto", index === namespaceData.length - 1 ? "rounded-b-lg" : ""].join(" ")}>
              <div className="flex rounded-lg shadow-xs">
                <div className="w-0 flex-1 p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                      <div className="ml-2 flex-shrink-0 w-14 h-14 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                        <div className="flex items-baseline text-xs leading-5 font-semibold text-indigo-600">
                          { ratioToPercentage(translatedRatio) }
                        </div>
                      </div>
                    </div>
                    <div className="ml-3 w-0 flex-1">
                      <p className="mt-2 text-sm leading-5 font-medium text-gray-900">{ namespace.name }</p>
                      <p className="text-sm leading-5 text-gray-500">{ (count && count.count) || 0 } keys</p>
                    </div>
                  </div>
                </div>
                <div className="flex border-l border-gray-200">
                  <Link to={`${url}/${language.locale_code}/${namespace.name}`} className="-ml-px flex items-center justify-center w-full border border-transparent rounded-r-lg p-4 text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-indigo-700 active:bg-gray-50 transition ease-in-out duration-150">
                    Edit
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="shadow w-full bg-grey-light rounded-md">*/}
          {/*  <div className="rounded-l-md bg-blue-500 text-xs leading-none py-1 text-center text-white" style={{width: ratioToPercentage(translatedRatio)}}>{ ratioToPercentage(translatedRatio) }</div>*/}
          {/*</div>*/}
        </div>
      )
    })
    return (
      <li key={language.id} className="col-span-1 bg-white rounded-lg shadow-lg divide-y divide-gray-200">
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3
                className="inline-flex text-xl items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800">
                { language.locale_code }
              </h3>
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{ languageKeyCount } keys</span>
            </div>
          </div>
          <div className="flex-1 text-right text-xs">
            { Project.reference_locale === language.locale_code ? '(Reference language)' : null }
          </div>
        </div>
        <div>
          <h3 className="px-6 pt-3 pb-3 text-lg leading-6 font-medium text-gray-600">Namespaces</h3>
        </div>
        <div>
          { namespaces && namespaces.length ? namespaces : <div className="px-6 py-3">
            <a onClick={e => {
              e.preventDefault();
              namespaceModal.current.open();
            }} className="text-indigo-600 hover:text-indigo-900" href="#">Add a namespace?</a>
          </div> }
        </div>
      </li>
    )
  })

  const closeNamespace = () => {
    namespaceModal.current.close();
  }
  const closeLanguage = () => {
    languageModal.current.close();
  }
  return <div>
    <Switch>
      <Route exact path={path}>
        <div className="mb-6">
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <button type="button"
                    onClick={() => languageModal.current.open()}
                    className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-indigo-300 text-white text-sm font-medium bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <svg className="mr-3 -ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
              Add new language
            </button>
            <button type="button"
                    onClick={() => namespaceModal.current.open()}
                    className="-ml-px relative inline-flex items-center px-4 py-2 border border-indigo-300 rounded-r-md text-white text-sm font-medium bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <svg className="mr-3 -ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
              Add new namespace
            </button>
          </span>
        </div>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
          { languageItems }
        </ul>
        <SmallModal ref={namespaceModal}>
          <NamespaceForm projectId={ projectId } close={ closeNamespace } />
        </SmallModal>

        <SmallModal ref={languageModal}>
          <LanguageForm projectId={ projectId } close={ closeLanguage } />
        </SmallModal>
      </Route>
      <Route path={`${path}/:language/:namespace/:translationFilter?`}>
        <List />
      </Route>
    </Switch>

  </div>
}
